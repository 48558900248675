import { Injectable } from '@angular/core';
import { ApiService } from '@shared/services/api.service';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap, catchError, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { StorageService } from '@shared/services/storage.service';
import { Router } from '@angular/router';
import _ from 'lodash';
import { ClientsService } from '@modules/clients/services/clients.service';
import { User } from '@shared/models/user';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    isAuthenticated = new BehaviorSubject<boolean>(false);

    User = new BehaviorSubject<User>(null);
    Groups = new BehaviorSubject<string[]>([]);

    private token: string;
    private isLoggingEnabled = !environment.production;

    get authToken(): string {
        return this.token;
    }

    constructor(
        private apiService: ApiService,
        private storage: StorageService,
        private router: Router,
        private clientsService: ClientsService
    ) {
        this.token = storage.getJwtToken();

        if (this.token) {
            this.isAuthenticated.next(true);
        }
    }

    /**
     * Handles login.
     * Returns true/false whether the authentication was successful or not
     * @param username Username input
     * @param password Password input
     */
    login(username: string, password: string): Observable<boolean> {
        // Remove any existing token to prevent the refreshing of an older one
        this.storage.setJwtToken(null);

        return this.apiService.getAuthToken(username, password).pipe(
            switchMap((result: { token: string }) => {
                if (this.isLoggingEnabled) console.warn('[Auth-Service] getAuthToken');

                if (result && result.token) {
                    this.token = result.token;
                    this.storage.setJwtToken(this.token);
                    this.isAuthenticated.next(true);
                    return of(true);
                } else {
                    throw new Error();
                }
            }),
            catchError((error) => {
                console.error('Login error', error);
                this.logout();
                return of(false);
            })
        );
    }

    /**
     * Handles logout.
     */
    logout(): void {
        if (this.isLoggingEnabled) console.warn('[Auth-Service] logout()');

        this.token = null;
        this.storage.setJwtToken(null);

        // Erase necessary sources
        this.clientsService.eraseClientsData();

        this.isAuthenticated.next(false);

        // Navigate to login page
        this.router.navigateByUrl('/autentificare');
    }

    /**
     * Handles authentication refresh.
     * Returns true/false whether the refresh was successful or not
     */
    refresh(): Observable<boolean> {
        return this.apiService.refreshAuthToken(this.token).pipe(
            switchMap((result) => {
                if (result.token) {
                    this.token = result.token;
                    this.storage.setJwtToken(result.token);

                    return of(true);
                }

                throw new Error('[Auth-Service] Token refresh invalid response');
            }),
            catchError((error) => {
                console.error('[Auth-Service] Token refresh error', error);
                this.logout();
                return of(false);
            })
        );
    }

    /**
     * Retrieves the user profile.
     */
    getUserProfile() {
        return this.apiService.getUserProfile().pipe(
            tap((result) => {
                this.User.next(result.user);
                this.Groups.next(result.groups);
            }),
            catchError((error) => {
                console.error('[Auth-Service] User profile fetching error', error);
                return of(null);
            })
        );
    }

    /**
     * Changes existing password
     * @param oldPassword Old password
     * @param newPassword New password
     */
    changePassword(oldPassword: string, newPassword: string): Observable<any> {
        return this.apiService.changePassword(oldPassword, newPassword);
    }
}
