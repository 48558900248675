import Field from '@shared/models/field';
import Task, { SanitizedTask } from '@shared/models/task';
import moment from 'moment';
import _ from 'lodash';

const MOMENT_SEPTEMBER = 8;

export function getAgricultureYearFromDate(date: string | Date): number | null {
    if (_.isEmpty(date) || !moment(date).isValid()) {
        return null;
    }

    const moment_date = moment(date);

    if (
        moment_date.isBefore(
            moment({
                year: moment_date.get('year'),
                month: MOMENT_SEPTEMBER,
                day: 1,
                hour: 0,
                minute: 0,
                second: 0,
            }),
        )
    ) {
        return moment_date.get('year');
    } else {
        return moment_date.get('year') + 1;
    }
}

export function removeDiacritics(value: any): string {
    return value
        .toString()
        .toUpperCase()
        .replace(/Â/g, 'A')
        .replace(/Â/g, 'A')
        .replace(/Ă/g, 'A')
        .replace(/Ă/g, 'A')
        .replace(/Ş/g, 'S')
        .replace(/Ţ/g, 'T')
        .replace(/Ă/g, 'A')
        .replace(/Î/g, 'I')
        .replace(/Î/g, 'I')
        .replace(/Ș/g, 'S')
        .replace(/Ș/g, 'S')
        .replace(/Ț/g, 'T')
        .replace(/Ț/g, 'T');
}

/**
 * Downloads a file with a given name
 *
 * @param uri: path to file
 * @param filename: wanted name
 */
export const downloadFile = (uri: string, filename: string): void => {
    const a = document.createElement('a');
    a.download = filename;
    a.href = uri;
    document.body.appendChild(a);
    a.click();
    a.remove();
};

export const sortFieldsByBF = (fields: Field[]): Field[] => {
    const orderedFields: Field[] = _.orderBy(fields, ['name'], ['asc']);

    const apiaFields: Field[] = _.remove(
        orderedFields,
        (field: Field) => _.includes(field.name, 'BF') && _.includes(field.name, 'parcela'),
    );

    apiaFields.sort(sortByBF);

    return _.sortBy([...apiaFields, ...orderedFields], 'apia_crop_type');
};

const sortByBF = (a: Field, b: Field): number => {
    if (!a.name || !b.name) {
        return 0;
    }

    const getBFNumber = (field: Field) =>
        Number.parseInt(field.name.slice(2, field.name.indexOf(' parcela')));
    const getAllotmentNumber = (field: Field) =>
        Number.parseInt(field.name.slice(field.name.indexOf('parcela') + 8, field.name.length - 1));

    const aBFNumber: number = getBFNumber(a);
    const bBFNumber: number = getBFNumber(b);

    if (aBFNumber > bBFNumber) {
        return 1;
    } else if (aBFNumber < bBFNumber) {
        return -1;
    } else {
        const aAllotmentNumber = getAllotmentNumber(a);
        const bAllotmentNumber = getAllotmentNumber(b);
        return aAllotmentNumber > bAllotmentNumber
            ? 1
            : aAllotmentNumber < bAllotmentNumber
              ? -1
              : 0;
    }
};

export const getTaskStatusText = (status: string): string =>
    status.toLowerCase() === 'ongoing' ? 'În desfășurare' : 'Finalizat';

export const getTaskStatusColor = (status: string): string =>
    status.toLowerCase() === 'ongoing' ? 'gold' : 'green';

export const getInspectionText = (task: Task | SanitizedTask) =>
    task.inspection_type === 'RISK' ? 'Risc' : 'Daună';
export const getInspectionTagColor = (task: Task | SanitizedTask) =>
    task.inspection_type === 'RISK' ? 'purple' : 'red';
