export enum LayerType {
    Outline = 'Outline',
    Vegetatie = 'Vegetatie',
}

export interface LayerItem {
    name: string;
    layerType: LayerType;
    iconFileName: string;
}
