<div class="Stats" [style.flexDirection]="isCompact ? 'column' : 'row'" *ngIf="stats">
    <div class="Flex">
        <div class="Stats-Heading" [style.marginLeft]="0" *ngIf="stats?.totalArea">
            <div class="Title">
                {{ stats?.totalArea | number: '1.0-2' }}
            </div>
            <div class="Title-Small">hectare</div>
        </div>

        <div class="Separator" *ngIf="stats?.fieldsCount"></div>

        <div class="Stats-Heading" *ngIf="stats?.fieldsCount">
            <div class="Title">
                {{ stats?.fieldsCount }}
            </div>
            <div class="Title-Small">
                {{ fields.length > 1 ? 'terenuri' : 'teren' }}
            </div>
        </div>
    </div>

    <div
        class="Crops-Stats"
        [class.Marginless]="isCompact"
        nz-tooltip
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="statsTemplate"
    >
        <div class="Chart">
            <div
                class="Line"
                *ngFor="let entry of stats.cropsStats; index as i"
                [ngStyle]="{
                    'background-color': entry.cropType.field_color,
                    width: entry.width.toFixed() + '%',
                    'z-index': i
                }"
            ></div>
        </div>

        <div class="Legend" *ngIf="stats.cropsStats">
            <div class="Legend-Item" *ngFor="let entry of stats.cropsStats | reverse">
                <div class="Dot" [style.backgroundColor]="entry.cropType.field_color"></div>
                <div class="Legend-Item-Text">
                    <span class="Legend-Item-Name">{{ entry?.cropType?.name }} </span>
                    <span class="Weight-500">• {{ entry?.area | number: '1.0-2' }} ha</span>
                </div>
            </div>
        </div>
    </div>

    <ng-template #statsTemplate>
        <div class="Tooltip-Container" *ngIf="stats.cropsStats">
            <div class="Tooltip-Item" *ngFor="let entry of stats.cropsStats | reverse">
                <div class="Tooltip-Item-Left">
                    <div
                        class="Dot"
                        [style.backgroundColor]="entry.cropType.field_color"
                        [class.Bordered]="entry.cropType.field_color == '#000000'"
                    ></div>
                    <div class="Tooltip-Text Tooltip-Name">
                        {{ entry?.cropType?.name }}
                    </div>
                </div>
                <div class="Tooltip-Item-Right">
                    <span class="Tooltip-Text Weight-500">{{ entry?.area | number: '1.0-2' }} ha</span>
                    <span class="Dot-Separator">•</span>
                    <span class="Tooltip-Text">{{ entry?.percentage | number: '1.1-1' }}%</span>
                </div>
            </div>
        </div>
    </ng-template>
</div>
