import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'shared-loading-screen',
    templateUrl: './loading-screen.component.html',
    styleUrls: ['./loading-screen.component.less']
})
export class LoadingScreenComponent implements OnInit {
    /**
     * Container styles
     */
    @Input()
    containerStyle: any;

    constructor() {}

    ngOnInit() {}
}
