import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import WeatherDataDaily from '@shared/models/weather';
import _ from 'lodash';

@Component({
    selector: 'shared-weather-legend',
    templateUrl: './weather-legend.component.html',
    styleUrls: ['./weather-legend.component.less'],
})
export class WeatherLegendComponent implements OnInit, OnChanges {
    @Input()
    data: any;

    weatherData: WeatherDataDaily;

    constructor() {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && changes.data.currentValue) {
            const dailyData: any[] = changes.data.currentValue.daily.data;

            if (!_.isEmpty(dailyData)) {
                this.weatherData = dailyData[0];
            }
        }
    }
}
