<div class="Legend">
    <div class="Wrapper">
        <div class="Title">Meteo</div>

        <div class="Container">
            <div class="Weather-Item">
                <i class="Round-Icon Temperature" nz-icon [inlineSVG]="'/assets/icons/meteo/temperature.svg'"></i>
                <div class="Text Flex-Row">
                    <div class="Min-Temperature">{{ weatherData?.temperatureMin | number: '1.0-1' }}</div>
                    <nz-divider nzType="vertical"></nz-divider>
                    <div class="Max-Temperature">{{ weatherData?.temperatureMax | number: '1.0-1' }}</div>
                    <div class="Separator">°C</div>
                </div>
            </div>

            <div class="Weather-Item">
                <i class="Round-Icon Rain" nz-icon [inlineSVG]="'/assets/icons/meteo/rain.svg'"></i>
                <div class="Text">{{ weatherData?.precipIntensity | number }} mm/h</div>
            </div>

            <div class="Weather-Item">
                <i class="Round-Icon Humidity" nz-icon [inlineSVG]="'/assets/icons/meteo/humidity.svg'"></i>
                <div class="Text">{{ weatherData?.humidity | number }} %</div>
            </div>

            <div class="Weather-Item">
                <i class="Round-Icon Wind" nz-icon [inlineSVG]="'/assets/icons/meteo/wind.svg'"></i>
                <div class="Text">{{ weatherData?.windSpeed | number }} m/s</div>
            </div>

            <div class="Weather-Item">
                <i class="Round-Icon Pressure" nz-icon [inlineSVG]="'/assets/icons/meteo/pressure.svg'"></i>
                <div class="Text">{{ weatherData?.pressure | number }} hPa</div>
            </div>
        </div>
    </div>
</div>
