import { Inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AppConfig, APP_CONFIG } from 'app-config';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    configuration: AppConfig;

    private isLoggingEnabled = !environment.production;

    constructor(@Inject(APP_CONFIG) private config: AppConfig) {}

    loadConfig() {
        if (this.isLoggingEnabled) console.log('[ConfigService] loadConfig', this.config);

        this.configuration = this.config;
    }
}
