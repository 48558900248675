import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    constructor() {}

    /**
     * Retrieves the JWT token from local storage.
     */
    getJwtToken(): string {
        return window.localStorage['jwt'];
    }

    /**
     * Sets a new JWT token to local storage.
     * If `token` parameter is null, the existing JWT token will be deleted from local storage.
     * @param token JWT token string
     */
    setJwtToken(token?: string | null): void {
        if (token) {
            window.localStorage['jwt'] = token;
        } else {
            window.localStorage.removeItem('jwt');
        }
    }

    /**
     * Retrieve last used client ID
     */
    getLastClientId(): string {
        return window.localStorage['c_id'];
    }

    /**
     * Sets last used client ID
     * @param clientEid Client external ID
     */
    setLastClientId(clientEid?: string): void {
        if (clientEid) {
            window.localStorage['c_id'] = clientEid;
        } else {
            window.localStorage.removeItem('c_id');
        }
    }
}
