// Field features filtering
export interface FilterOption {
    type: FilterOptionType;
    value: string | number;
}

export enum FilterOptionType {
    Prefix,
    CropType,
    FieldName,
}
