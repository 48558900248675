import { Component, Input } from '@angular/core';

@Component({
    selector: 'shared-message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.less'],
})
export class MessageBoxComponent {
    @Input()
    text: string;

    @Input()
    subtext: string;

    @Input()
    type: 'info' | 'error';

    @Input()
    containerStyle: any;

    isSpinning = false;

    constructor() {}

    onReload = (): void => {
        this.isSpinning = true;
        setTimeout(() => (this.isSpinning = false), 500);
    };
}
