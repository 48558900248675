import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import getRainbowVector from '@shared/utils/rainbow';
import { LayerType } from '@shared/models/map/layer';
import { NdviData } from '@shared/models/map/ndvi';
import _ from 'lodash';

@Component({
    selector: 'shared-map-legend',
    templateUrl: './map-legend.component.html',
    styleUrls: ['./map-legend.component.less'],
})
export class MapLegendComponent implements OnInit, OnChanges {
    @Input()
    entry: NdviData;

    rainbows = {
        Vegetatie: [],
    };

    LayerType = LayerType;

    /**
     * Chart-related variables
     */
    labels: string[] = ['Roșu', 'Portocaliu', 'Galben', 'Verde deschis', 'Verde inchis', 'Gri'];

    options: any = {
        tooltips: { enabled: false },
        hover: { mode: null },
        responsive: true,
        aspectRatio: 1,
        legend: {
            display: false,
        },
        segmentShowStroke: false,
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
    };

    chartData: number[] = [];
    colorsArray: string[] = ['red', 'darkorange', 'yellow', 'yellowgreen', 'green', 'lightgray'];

    colors = [
        {
            backgroundColor: this.colorsArray,
        },
    ];

    legendItems = [];

    constructor() {}

    ngOnInit(): void {
        let rainbow: any[];

        rainbow = getRainbowVector(['red', 'yellow', 'green']);

        this.rainbows[LayerType.Vegetatie] = rainbow.map(
            (element: any) => `rgb(${element.red}, ${element.green}, ${element.blue})`
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.entry && changes.entry.currentValue) {
            const bins: number[] = changes.entry.currentValue.colour_bins;

            if (bins) {
                this.chartData = bins;
                const total = _.sum(bins);
                const items = [];

                for (let i = 0; i < bins.length; i++) {
                    if (bins[i]) {
                        items.push({
                            index: i,
                            name: this.labels[i],
                            value: (bins[i] / total) * 100,
                            color: this.colorsArray[i],
                        });
                    }
                }

                this.legendItems = _.orderBy(items, ['value'], ['desc']);
            }
        }
    }
}
