/**
 * @module ol/render/canvas/BuilderType
 */
/**
 * @enum {string}
 */
export default {
    CIRCLE: 'Circle',
    DEFAULT: 'Default',
    IMAGE: 'Image',
    LINE_STRING: 'LineString',
    POLYGON: 'Polygon',
    TEXT: 'Text',
};
