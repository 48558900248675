<div class="Flex-Row Box" [ngStyle]="containerStyle">
    <i class="Icon" nz-icon [nzType]="type === 'info' ? 'info-circle' : 'exclamation-circle'" nzTheme="outline"></i>

    <div class="Flex-Column Text-Wrapper">
        <div class="Sub-Text">{{ subtext }}</div>
        <div class="Text">{{ text }}</div>
    </div>

    <div>
        <i (click)="onReload()" class="Reload-Icon" nz-icon nzType="reload" [nzSpin]="isSpinning" nzTheme="outline"></i>
    </div>
</div>
