import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from './services/api.service';
import { FormsModule } from '@angular/forms';
import { StorageService } from './services/storage.service';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { CropTagComponent } from './components/crop-tag/crop-tag.component';
import { ReversePipe } from './utils/reverse-pipe';
import { MapLegendComponent } from './components/map-legend/map-legend.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { WeatherLegendComponent } from './components/weather-legend/weather-legend.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { FieldStatsComponent } from './components/field-stats/field-stats.component';
import { CapitalizeFirstPipe } from './utils/capitalize-first-pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NzDividerModule,
        NzIconModule,
        NzButtonModule,
        NzToolTipModule,
        FormsModule,
        InlineSVGModule.forRoot(),
    ],
    declarations: [
        LoadingScreenComponent,
        CropTagComponent,
        ReversePipe,
        CapitalizeFirstPipe,
        MapLegendComponent,
        WeatherLegendComponent,
        MessageBoxComponent,
        FieldStatsComponent,
    ],
    providers: [ApiService, StorageService],
    exports: [
        LoadingScreenComponent,
        CropTagComponent,
        ReversePipe,
        CapitalizeFirstPipe,
        MapLegendComponent,
        WeatherLegendComponent,
        MessageBoxComponent,
        FieldStatsComponent,
    ],
})
export class SharedModule {}
