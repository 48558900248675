import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import CropType from '@shared/models/crop-type';
import Field from '@shared/models/field';
import { LayersService } from '@core/services/layers.service';
import _ from 'lodash';

export interface Stats {
    totalArea?: number;
    fieldsCount?: number;
    cropsStats?: Array<{
        cropType: CropType;
        area: number;
        width: number;
        percentage: number;
    }>;
}

@Component({
    selector: 'fields-stats',
    templateUrl: './field-stats.component.html',
    styleUrls: ['./field-stats.component.less'],
})
export class FieldStatsComponent implements OnChanges {
    @Input()
    fields: Field[] = [];

    @Input()
    fieldStats: Stats = {};

    @Input()
    sizeLimit = 24;

    @Input()
    set setCompactMode(value: Boolean) {
        this.isCompact = value.toString() !== 'false';
    }

    stats: Stats = {};

    isCompact = false;

    constructor(private layersService: LayersService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fields) {
            this.fields = changes.fields.currentValue;
            this.computeStats(this.fields);
        }

        if (changes.fieldStats) {
            if (changes.fieldStats.currentValue) {
                this.stats = {
                    cropsStats: this.sliceStats(changes.fieldStats.currentValue.cropsStats),
                };
            }
        }
    }

    computeStats = (fields: Field[]): void => {
        const stats = {};
        let statsArray = [];

        _.forEach(fields, (field: Field) => {
            if (!stats[field.apia_crop_type]) {
                stats[field.apia_crop_type] = field.declared_area
                    ? field.declared_area
                    : field.computed_area;
            } else {
                stats[field.apia_crop_type] += field.declared_area
                    ? field.declared_area
                    : field.computed_area;
            }
        });

        const totalArea = _.sum(Object.values(stats));

        for (const [key, value] of Object.entries(stats)) {
            const cropType: CropType = this.layersService.getCropType(Number.parseInt(key));
            const percentage: number = ((value as number) * 100) / totalArea;

            statsArray.push({
                cropType,
                area: value,
                percentage,
                width: percentage,
            });
        }

        statsArray = _.orderBy(statsArray, 'width', 'desc');

        statsArray.forEach((entry, index, array) => {
            if (index > 0) {
                entry.width += array[index - 1].width;
            }
        });

        this.stats.totalArea = totalArea;
        this.stats.fieldsCount = _.size(fields);
        this.stats.cropsStats = this.sliceStats(_.orderBy(statsArray, 'width', 'desc'));
    };

    /**
     * Slices the stats array if it's too long, so it can be displayed properly.
     * Returns an array with the sliced results.
     * @param cropsStats Array of ordered cropsStats
     */
    sliceStats = (
        cropsStats: Array<{
            cropType: CropType;
            area: number;
            width: number;
            percentage: number;
        }>
    ): Array<{
        cropType: CropType;
        area: number;
        width: number;
        percentage: number;
    }> => {
        const length = _.size(cropsStats);

        if (length > this.sizeLimit) {
            const array = _.cloneDeep(cropsStats);

            const splicingIndex = length - this.sizeLimit;
            const result = array.splice(splicingIndex);

            const others = {
                area: _.sumBy(array, 'area'),
                cropType: {
                    code: -1,
                    field_color: '#777777',
                    id: -1,
                    name: 'ALTE CULTURI',
                    text_color: '#FFFFFF',
                },
                percentage: _.sumBy(array, 'percentage'),
                width: 100,
            };

            result.unshift(others);

            return result;
        } else {
            return cropsStats;
        }
    };
}
