import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import CropType from '@shared/models/crop-type';

@Component({
    selector: 'shared-crop-tag',
    templateUrl: './crop-tag.component.html',
    styleUrls: ['./crop-tag.component.less'],
})
export class CropTagComponent implements OnInit {
    @Input()
    cropType: CropType;

    @Input()
    set setWrapping(value: Boolean) {
        this.isWrapped = value.toString() !== 'false';
    }

    @Input()
    set setFullWidth(value: Boolean) {
        this.isFullWidth = value.toString() !== 'false';
    }

    @Input()
    size: 'small' | 'large' = 'large';

    /**
     * If a CropType is provided, its name is used.
     * Otherwise, the provided text is displayed.
     */
    @Input()
    text: string;

    @Output()
    clickEvent = new EventEmitter<number>();

    public cropStyle: any;
    public nameStyle: object;

    private isFullWidth = false;
    private isWrapped = false;

    constructor() {}

    ngOnInit() {
        if (this.cropType) {
            this.cropStyle = {
                color: this.cropType.field_color,
                border: '1px solid ' + this.cropType.field_color,
                'background-color': this.cropType.field_color + '14',
                width: this.isFullWidth ? '100%' : 'fit-content',
                'max-width': '100%',
                'font-size': this.size === 'small' ? '12px' : '13px',
            };

            this.text = this.cropType.name;

            // As white on white is not visible, we use special styling
            if (this.cropType.field_color.toLowerCase() === '#ffffff') {
                this.cropStyle.border = '1px solid #000000';
                this.cropStyle.color = '#000000';
                this.cropStyle['background-color'] = '#fbfbfb';
            }
        } else {
            const primaryColor = '#09b1b9';

            this.cropStyle = {
                color: primaryColor,
                border: '1px solid ' + primaryColor,
                'background-color': primaryColor + '14',
                width: this.isFullWidth ? '100%' : 'fit-content',
                'max-width': '100%',
                'font-size': this.size === 'small' ? '12px' : '13px',
            };
        }

        this.nameStyle = {
            'white-space': this.isWrapped ? 'unset' : 'nowrap',
        };
    }

    onClick(): void {
        if (this.cropType) this.clickEvent.emit(this.cropType.id);
    }
}
