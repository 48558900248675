import Rainbow from 'rainbowvis.js';

const rainbow = new Rainbow();

export default function getRainbowVector(colorSpectrum: string[]): any[] {
    const minRange = 0;
    const maxRange = 249;

    rainbow.setSpectrumByArray(colorSpectrum);

    const vector = [];
    rainbow.setNumberRange(minRange, maxRange);

    for (let k = minRange; k <= maxRange; k++) {
        const obj = {
            red: parseInt(rainbow.colourAt(k).substring(0, 2), 16),
            green: parseInt(rainbow.colourAt(k).substring(2, 4), 16),
            blue: parseInt(rainbow.colourAt(k).substring(4, 6), 16)
        };

        vector.push(obj);
    }

    return vector;
}
