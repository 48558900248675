/**
 * @module ol/ObjectEventType
 */
/**
 * @enum {string}
 */
export default {
    /**
     * Triggered when a property is changed.
     * @event module:ol/Object.ObjectEvent#propertychange
     * @api
     */
    PROPERTYCHANGE: 'propertychange',
};
