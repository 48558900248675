import { Injectable } from '@angular/core';
import { Observable, forkJoin, throwError, of, BehaviorSubject } from 'rxjs';
import { ApiService } from '@shared/services';
import { AuthService } from '@modules/auth/services/auth.service';
import { catchError, switchMap, tap, filter, take, shareReplay, delay } from 'rxjs/operators';
import CropType from '@shared/models/crop-type';
import Field from '@shared/models/field';
import { ClientsService } from '@modules/clients/services/clients.service';
import _ from 'lodash';
import olVectorImage from 'ol/layer/VectorImage';
import olLayerGroup from 'ol/layer/Group';
import olLayerImage from 'ol/layer/Image';
import olCollection from 'ol/Collection';
import olSourceVector from 'ol/source/Vector';
import olSourceCluster from 'ol/source/Cluster';
import olGeomLineString from 'ol/geom/LineString';
import polylabel from 'polylabel';
import olFormatWKT from 'ol/format/WKT';
import olPolygon from 'ol/geom/Polygon';
import olGeomPoint from 'ol/geom/Point';
import olGeometry from 'ol/geom/Geometry';
import olFeature from 'ol/Feature';
import olProjection from 'ol/proj/Projection';

// Area and projection related imports
import * as proj4x from 'proj4';
import { register } from 'ol/proj/proj4';
import { get } from 'ol/proj.js';
import { environment } from '@environments/environment';
import { Extent as olExtent } from 'ol/extent';
import { Client } from '@shared/models/client';
import { FilterOption, FilterOptionType } from '@shared/models/filter';
import { LayerItem, LayerType } from '@shared/models/map/layer';
import { NdviData } from '@shared/models/map/ndvi';
import { removeDiacritics } from '@shared/utils/utils';
import { FieldInfo } from '@shared/models/field-info';

const proj4 = (proj4x as any).default;

@Injectable({
    providedIn: 'root',
})
export class LayersService {
    wkt: olFormatWKT = new olFormatWKT();

    Fields: BehaviorSubject<Field[]> = new BehaviorSubject<Field[]>([]);
    CropTypes: CropType[] = [];
    FieldChildren: Field[] = [];

    isLoadingFields: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    layerItems: Array<LayerItem> = [
        {
            name: 'Contur terenuri',
            layerType: LayerType.Outline,
            iconFileName: `${LayerType.Outline}.png`,
        },
        {
            name: 'Vegetație',
            layerType: LayerType.Vegetatie,
            iconFileName: `${LayerType.Vegetatie}.png`,
        },
    ];

    currentLayerItem: BehaviorSubject<LayerItem> = new BehaviorSubject<LayerItem>(
        this.layerItems[0],
    );
    currentFilters: BehaviorSubject<FilterOption[]> = new BehaviorSubject<FilterOption[]>([]);

    private stereo70Projection: olProjection;
    private isLoggingEnabled = !environment.production;

    private currentClient: Client = null;

    /**
     * The timesliderDataSource is shared among subscribers, so that the API isn't called multiple
     * times for the same data. The timesliderDataFieldId contains the id of the field for which the
     * data is currently shared. If data is requested for a different field than the current one,
     * the source is erased and the API is called again
     */
    private timesliderDataSource: Observable<NdviData[]> = null;
    private timesliderDataFieldId: number = null;

    /**
     * An Observable which emits the shared HTTP call only once for the requesting initial subscribers
     * (the ones that subscribe during the first lifespan of the first observer created), as well as for
     * the late subscribers.
     */
    private ndviIndexSource: Observable<any> = null;

    constructor(
        private apiService: ApiService,
        private authService: AuthService,
        private clientsService: ClientsService,
    ) {
        this.createStereo70Projection();

        this.clientsService.CurrentClient.pipe(
            /**
             * Filter null or duplicate CurrentClient values. If CurrentClient emits a null value,
             * the Fields are set to an empty array
             */
            filter((client: Client) => {
                if (!client) {
                    this.Fields.next([]);
                    return false;
                } else if (!this.currentClient) {
                    return true;
                } else {
                    return client.id !== this.currentClient.id;
                }
            }),
            /**
             * If layersService.currentClient is the first one set (its previous value is null),
             * then we skip the getFields() request as it was already called in the DataLoader
             */
            switchMap((client: Client) => {
                const shouldGetFields: boolean = !!this.currentClient;
                this.currentClient = client;

                this.isLoadingFields.next(shouldGetFields);

                return shouldGetFields ? this.getFields() : of(true);
            }),
            tap(() => this.isLoadingFields.next(false)),
        ).subscribe();
    }

    /**
     * Clears session-dependent data at logout
     */
    cleanUp() {
        this.eraseNdviData();
        this.eraseTimesliderData();
        this.Fields.next([]);

        this.currentFilters.next([]);
        this.currentLayerItem.next(this.layerItems[0]);
    }

    eraseTimesliderData = (): void => {
        if (this.isLoggingEnabled) console.log('[Layers-Service] eraseTimesliderData');
        this.timesliderDataSource = null;
        this.timesliderDataFieldId = null;
    };

    eraseNdviData = (): void => {
        if (this.isLoggingEnabled) console.log('[Layers-Service] eraseNdviData');
        this.ndviIndexSource = null;
        this.eraseTimesliderData();
    };

    /**
     * Provides the fields parsing URL
     */
    get LayersParserURL(): string {
        return this.apiService.apiUrl + '/core/parser/';
    }

    get FieldsAndChildren(): Field[] {
        return [...this.Fields.getValue(), ...this.FieldChildren];
    }

    /**
     * Retrieves the fields for the current client
     */
    getFields(): Observable<Field[]> {
        if (!this.currentClient) return of([]);

        return this.apiService.getFields(this.currentClient.id).pipe(
            tap((fields: Field[]) => {
                this.Fields.next(_.filter(fields, (field) => !field.parent));
                this.FieldChildren = _.filter(fields, (field) => !!field.parent);
            }),
        );
    }

    /**
     * Returns the fields of the given client
     */
    getFieldsOfClient(clientId: number): Observable<Field[]> {
        return this.apiService.getFields(clientId);
    }

    /**
     * Retrieves the child fields of the given field for the current client
     * @param fieldId: The field's id
     */
    getChildrenOfField(fieldId: number): Observable<Field[]> {
        if (!this.currentClient) return of([]);

        return this.getFields().pipe(
            switchMap((fields: Field[]) => {
                const children: Field[] = _.filter(fields, (field) => field.parent === fieldId);

                return of(children);
            }),
        );
    }

    /**
     * Returns a previously fetched field
     */
    getField = (id: number): Field => {
        if (!id) return null;
        return this.Fields.getValue().find((field) => field.id === id);
    };

    /**
     * Fetches a single field
     */
    fetchField = (clientId: number, fieldId: number): Observable<Field> => {
        return this.apiService.getField(clientId, fieldId);
    };

    /**
     * Deletes a field
     * @param fieldId: The field's id
     */
    deleteField(fieldId: number): Observable<any> {
        const currentClient: Client = this.clientsService.CurrentClient.getValue();
        if (!currentClient) return of(false);

        return this.apiService.deleteField(currentClient.id, fieldId).pipe(
            tap((result: any) => {
                if (this.isLoggingEnabled) console.log('[Layers-Service] deleteField', result);
                this.Fields.next(this.Fields.getValue().filter((field) => field.id !== fieldId));
            }),
        );
    }

    /**
     * Updates a field with the given properties
     * @param fieldId: The field's id
     * @param data An object containing the properties to be changed and their new values
     */
    updateField(fieldId: number, data: any): Observable<any> {
        if (!this.currentClient) return of(false);

        this.sanitizeFieldsArray([data], false);

        return this.apiService.updateField(this.currentClient.id, fieldId, data).pipe(
            tap((field: Field) => {
                const updatedField: Field = this.FieldsAndChildren.find(
                    (value) => value.id === field.id,
                );

                if (_.isEmpty(updatedField)) {
                    console.error('Cannot find Field to update', fieldId);
                    return;
                }

                _.forOwn(data, (value, property) => {
                    if (this.isLoggingEnabled)
                        console.warn(`[Layers-Service] Updating ${property} into ${value}`);
                    updatedField[property] = value;
                });
            }),
        );
    }

    /**
     * Returns an array of unique crop types found in the given array of fields
     * @param fields Array of Field objects
     */
    extractCropTypes(fields: Field[]): CropType[] {
        const cropTypeIds = new Set();

        fields.forEach((field: Field) => cropTypeIds.add(field.apia_crop_type));

        const cropTypesArray: CropType[] = Array.from(cropTypeIds).map((cropTypeId: number) =>
            this.getCropType(cropTypeId),
        );

        return cropTypesArray;
    }

    /**
     * Filters the given fields and returns the ones who match the given crop types
     */
    getFieldsWithCropTypes(fields: Field[], cropTypeIds: number[]): Field[] {
        return _.filter(fields, (field: Field) => _.includes(cropTypeIds, field.apia_crop_type));
    }

    /**
     * Creates fields for the current client
     * @param fieldsArray Array of fields to be created
     * @param replaceFields If true, all existing fields will be deleted.
     */
    createFields(fieldsArray: Field[], replaceFields: boolean = false): Observable<any> {
        if (!this.currentClient) return of([]);

        this.sanitizeFieldsArray(fieldsArray);

        return this.apiService.createFields(fieldsArray, this.currentClient.id, replaceFields);
    }

    /**
     * Returns an array of crop types
     */
    getCropTypes(): Observable<CropType[]> {
        return this.apiService.getApiaCropTypes().pipe(
            tap((cropTypes: CropType[]) => {
                this.CropTypes = cropTypes;
            }),
        );
    }

    /**
     * Searches a crop type by id
     * @param id The crop type id
     */
    getCropType(id: number): CropType {
        const defaultCropType: CropType = _.find(this.CropTypes, (cropType) => cropType.id === -1);

        if (!id) {
            return defaultCropType;
        }

        const result = _.find(this.CropTypes, (cropType) => cropType.id === id);
        return result || defaultCropType;
    }

    /**
     * Retrieves the initialization data
     */
    getInitializationData = (): Observable<any> => {
        return this.clientsService.getClients().pipe(
            switchMap(() => {
                return forkJoin([this.getFields(), this.getCropTypes()]);
            }),
            catchError(this.initializationDataErrorHandler),
        );
    };

    getLayerItem = (layerType: LayerType): LayerItem =>
        this.layerItems.filter((value) => value.layerType === layerType).pop();

    /**
     * Returns true if the given layerType containss NDVI imagery
     * @param layerType The layerType to be verified
     */
    isNdviLayerType = (layerType: LayerType): boolean => layerType === LayerType.Vegetatie;

    /**
     * Fetches historical information about the imageset of the field
     * @param fieldId The field's id
     */
    getTimesliderData(fieldId: number): Observable<NdviData[]> {
        if (!this.currentClient) return of(null);

        if (
            !this.timesliderDataSource ||
            (this.timesliderDataSource && fieldId !== this.timesliderDataFieldId)
        ) {
            this.timesliderDataFieldId = fieldId;
            this.timesliderDataSource = this.apiService
                .getFieldNdviIndex(this.currentClient.id, fieldId)
                .pipe(shareReplay(1));
        }

        return this.timesliderDataSource;
    }

    /**
     * Returns an object of type: { fieldId: NdviData }
     */
    getNdviIndex(): Observable<{
        fieldId: NdviData;
    }> {
        if (!this.currentClient) return of(null);

        if (!this.ndviIndexSource) {
            this.ndviIndexSource = this.apiService.getNdviIndex(this.currentClient.id).pipe(
                switchMap((ndviDataArray: NdviData[]) => {
                    return of(_.keyBy(ndviDataArray, (obj) => obj.field));
                }),
                tap((result) => console.log('getNdviIndex()', result)),
                shareReplay(1),
            );
        }

        return this.ndviIndexSource;
    }

    convertPolygonCoordinatesArrayToWkt(coordinates: any): string {
        return this.wkt.writeGeometry(new olPolygon(coordinates as [number, number][][]));
    }

    getBoundingBoxOfLayerGroup(layerGroup: olLayerGroup): olExtent {
        for (const layer of layerGroup.getLayers().getArray()) {
            if (layer.get('canProvideExtent')) {
                return ((layer as olVectorImage).getSource() as olSourceVector).getExtent();
            }
        }
    }

    getBoundingBoxOfPolygonCoordinates(coordinates: string): number[] {
        const polygonGeometry: olPolygon = this.readFeature(coordinates).getGeometry() as olPolygon;
        return polygonGeometry.getExtent();
    }

    getBoundingBoxOfFieldsIds(fieldsIds: number[]): number[] {
        const featuresCollection = new olCollection<olFeature>();

        this.Fields.getValue().map((field: Field) => {
            if (fieldsIds.includes(field.id)) {
                featuresCollection.push(this.readFeature(field.geometry));
            }
        });

        return new olSourceVector({
            features: featuresCollection,
        }).getExtent();
    }

    /**
     * Parses the given coordinates of a feature and returns an olFeature (point or polygon)
     * @param coordinates The coordinates of the feature, as string or array
     */
    readFeature(coordinates: any): olFeature {
        switch (typeof coordinates) {
            case 'string':
                return this.wkt.readFeature(coordinates.slice(coordinates.indexOf(';') + 1), {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:4326',
                });

            case 'object':
                if (
                    coordinates.length === 2 &&
                    typeof coordinates[0] === 'number' &&
                    typeof coordinates[1] === 'number'
                ) {
                    return new olFeature({
                        geometry: new olGeomPoint(coordinates as [number, number]),
                    });
                } else {
                    return new olFeature({
                        geometry: new olPolygon(coordinates as [number, number][][]),
                    });
                }

            default:
                console.error('Unknown field coordinates type', typeof coordinates);
                return null;
        }
    }

    /**
     * Returns the total area in the given set
     * @param fields Array of Field objects
     */
    getSetArea(fields: Field[], precision: number = 3): number {
        let area = 0;

        for (const field of fields) {
            if (field.declared_area) {
                area += field.declared_area;
            } else {
                area += field.computed_area;
            }
        }

        return Number.parseFloat(Number(area).toPrecision(precision));
    }

    /**
     * Returns the area of the polygon in 'ha'
     * @param geometry The geometry of the polygon
     */
    getPolygonArea(geometry: olGeometry): number {
        this.createStereo70Projection();

        // Clone
        const coordinates = (geometry as olPolygon).getCoordinates();
        const geom: olGeometry = new olPolygon(coordinates) as olGeometry;

        try {
            geom.transform('EPSG:4326', this.stereo70Projection);
        } catch (error) {
            // When we need to compute the area of a field that is being drawn in real-time,
            // the coordinates are sometimes received as NaN's and this type of error is being ignored
            if (!_.isEmpty(coordinates) && !isNaN(coordinates[0][0][0])) {
                if (this.isLoggingEnabled) console.warn(error);
            }
        }

        return (geom as olPolygon).getArea() / 10000;
    }

    /**
     * Reads a polygon feature and returns it's coordinates as an array of numbers
     * @param coordinates The polygon's coordinates
     */
    getPolygonCoordinates(coordinates: any): Array<any> {
        const feature: olFeature = this.readFeature(coordinates);
        const poly: olPolygon = feature.getGeometry() as olPolygon;

        return poly.getCoordinates();
    }

    /**
     * Returns the pole of inaccessibility of the given multipolyon
     * @param coordinates Coordinates string in well-known text
     */
    getPoleOfInaccessibility(coordinates: any): number[] {
        const polygonGeometry: olPolygon = this.readFeature(coordinates).getGeometry() as olPolygon;
        return polylabel(polygonGeometry.getCoordinates());
    }

    /**
     * Returns the length of the given lineString in meters
     * @param lineString The lineString geometry which will be measured
     */
    getLineStringLength(lineString: olGeomLineString): number {
        const copy: olGeomLineString = lineString.clone() as olGeomLineString;
        const geometry: olGeometry = copy as olGeometry;

        try {
            geometry.transform('EPSG:4326', this.stereo70Projection);
        } catch (error) {
            console.error(error);
        }

        return (geometry as olGeomLineString).getLength();
    }

    /**
     * Sanitize certain properties of an array of fields, which need
     * to have a certain type in order to be saved correctly in the database
     */
    sanitizeFieldsArray = (fields: Field[], useDefaultCropType = true): void => {
        if (this.isLoggingEnabled) console.warn('[Layers-Service] sanitizeFieldsArray', fields);

        _.forEach(fields, (field: any) => {
            // Convert array coordinates to WKT strings
            if (field.geometry && typeof field.geometry !== 'string') {
                field.geometry = `SRID=4326;${this.convertPolygonCoordinatesArrayToWkt(
                    field.geometry,
                )}`;
            }

            // Create a name for the field if it has none
            if (field.id) {
                field.name = field.name || field.id.toString();
            }

            // Convert the cropType to its id
            if (useDefaultCropType && field.apia_crop_type === undefined) {
                field.apia_crop_type = -1;
            } else {
                if (field.apia_crop_type && typeof field.apia_crop_type !== 'number') {
                    field.apia_crop_type = field.apia_crop_type.id;
                }
            }
        });
    };

    /**
     * Register the Dealul Piscului 1970/ Stereo 70 Projection if it's not already created
     */
    private createStereo70Projection(): void {
        if (!this.stereo70Projection) {
            proj4.defs(
                'EPSG:31700',
                // tslint:disable-next-line: max-line-length
                '+proj=sterea +lat_0=46 +lon_0=25 +k=0.99975 +x_0=500000 +y_0=500000 +ellps=krass +towgs84=28,-121,-77,0,0,0,0 +units=m +no_defs',
            );

            register(proj4);

            this.stereo70Projection = get('EPSG:31700');
        }
    }

    /**
     * Handles thrown errors during initialization
     * @param errorResponse The error received
     */
    private initializationDataErrorHandler = (errorResponse: Response): Observable<any> => {
        console.error('[Layers-Service] Initialization error', errorResponse);
        this.authService.logout();
        return throwError(errorResponse || 'Initialization error');
    };

    /**
     * Filters the layers referenced in the given layer group using the current filters
     * @param layerGroup Group of layers to be filtered
     */
    filterLayerGroup(layerGroup: olLayerGroup): void {
        if (layerGroup && layerGroup.get('fieldsCount') && this.currentFilters.getValue()) {
            const filteredFieldsIds: number[] = this.filterCurrentFields(
                this.currentFilters.getValue(),
            );

            layerGroup.getLayers().forEach((layer) => {
                if (!layer.get('bingMaps') && !layer.get('geolocation')) {
                    if (
                        layer instanceof olVectorImage &&
                        (layer.get('isFieldsLayer') || layer.get('isDescriptorsLayer'))
                    ) {
                        const fullCollection: olCollection<olFeature> = layer.get('fullCollection');

                        if (fullCollection) {
                            let source: olSourceVector = layer.getSource() as olSourceVector;

                            if (source instanceof olSourceCluster) {
                                source = (source as olSourceCluster).getSource();
                            }

                            const currentCollection = source.getFeaturesCollection();
                            currentCollection.clear();

                            fullCollection.forEach((feature) => {
                                const fieldInfo: FieldInfo = feature.get('fieldInfo');

                                if (
                                    filteredFieldsIds.length === 0 ||
                                    filteredFieldsIds.includes(fieldInfo.fieldId)
                                ) {
                                    currentCollection.push(feature);
                                }
                            });
                        }
                    }

                    if (layer instanceof olLayerImage) {
                        const fieldInfo: FieldInfo = layer.get('fieldInfo');
                        if (
                            filteredFieldsIds.length === 0 ||
                            filteredFieldsIds.includes(fieldInfo.fieldId)
                        ) {
                            layer.setVisible(true);
                        } else {
                            layer.setVisible(false);
                        }
                    }
                }
            });
        }
    }

    /**
     * Filters the current fields with the given FilterOptions and returns
     * an array with the IDs of the filtered fields
     */
    filterCurrentFields = (filterOptions: FilterOption[]): number[] => {
        const filteredFields: Field[] = _.filter(this.Fields.getValue(), (field: Field) =>
            filterOptions
                .map((filterOption) => this.fieldMatchesFilter(field, filterOption))
                .some((value) => value),
        );

        return filteredFields.map((field: Field) => field.id);
    };

    /**
     * Returns the crop type field stats
     *
     * @param year: Optionally get stats for a year
     */
    getFieldsCropTypeStats = (year?: number) => {
        return this.apiService.getFieldsCropTypeStats(year);
    };

    /**
     * Checks if field matches the given filter
     * @param field Field to check
     * @param filter Filter option
     */
    private fieldMatchesFilter(field: Field, filterOption: FilterOption): boolean {
        let fieldName: string;
        const filterValue: string = removeDiacritics(filterOption.value);

        switch (filterOption.type) {
            case FilterOptionType.Prefix:
                fieldName = removeDiacritics(field.name || field.id);
                const splitName: string[] = _.split(fieldName, ' ');

                if (_.size(splitName) > 1) {
                    return _.head(splitName) === filterValue;
                }

                return false;
            case FilterOptionType.CropType:
                const cropType: string = removeDiacritics(
                    this.getCropType(field.apia_crop_type).name,
                );
                return cropType === filterValue;
            case FilterOptionType.FieldName:
                fieldName = removeDiacritics(field.name || field.id);
                return fieldName === filterValue;
            default:
                return false;
        }
    }
}
