<div class="Legend">
    <div class="Wrapper">
        <div>
            <div class="Title">Vegetație</div>
            <div *ngIf="rainbows[LayerType.Vegetatie]" class="Points-Container">
                <div class="Point" *ngFor="let color of rainbows[LayerType.Vegetatie]" [style.background]="color"></div>
            </div>

            <div class="Text-Wrapper">
                <span style="margin-left: 1px">Slabă</span> <span style="margin-right: 1px">Dezvoltată</span>
            </div>
        </div>

        <div>
            <div class="Chart" *ngIf="chartData.length > 0">
                <!-- <canvas baseChart [data]="chartData" [options]="options" [type]="'doughnut'"> </canvas> -->
            </div>

            <div class="Values-Container">
                <div class="Chart-Legend">
                    <div class="Item" *ngFor="let item of legendItems">
                        <div class="Dot" [ngStyle]="{ 'background-color': item.color }"></div>
                        <div>{{ item.name }} - {{ item.value | number: '1.1-1' }} %</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
