/**
 * @module ol/ViewHint
 */
/**
 * @enum {number}
 */
export default {
    ANIMATING: 0,
    INTERACTING: 1,
};
