import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { stringify } from 'query-string';
import { Observable, throwError, of, concat } from 'rxjs';
import { catchError, retryWhen, switchMap, take, delay } from 'rxjs/operators';
import CropType from '@shared/models/crop-type';
import Field from '@shared/models/field';
import { NdviData } from '@shared/models/map/ndvi';
import { Pin } from '@shared/models/pins/pin';
import Document from '@shared/models/document';
import Task, { TaskPage } from '@shared/models/task';
import { User } from '@shared/models/user';
import { GlobalSetting } from '@shared/models/settings';
import { Client, ClientSet } from '@shared/models/client';
import _ from 'lodash';
import { ConfigService } from './config.service';

const retryRanges = [
    [100, 199],
    [429, 429],
    [500, 599],
];

const requestFailedOperator = retryWhen((errorObservable) => {
    let numberOfRetries = 1;

    return errorObservable.pipe(
        switchMap((error: any) => {
            let isInRange = false;
            for (const [min, max] of retryRanges) {
                const status = error.status;
                if (status >= min && status <= max) {
                    isInRange = true;
                    break;
                }
            }

            if (!isInRange || numberOfRetries > 3) {
                return throwError(error);
            }

            const currentRetryDelay = ((Math.pow(2, numberOfRetries) - 1) / 2) * 1000;
            console.log(
                `[API-Service] Retrying request #${numberOfRetries} after ${currentRetryDelay}`,
            );

            numberOfRetries++;
            return of(error.status).pipe(delay(currentRetryDelay));
        }),
        take(3),
        (observable) => concat(observable, throwError('[API-Service] Number of retries exceeded.')),
    );
});

@Injectable()
export class ApiService {
    apiUrl: string;

    constructor(
        public http: HttpClient,
        private configService: ConfigService,
    ) {
        this.apiUrl = this.configService.configuration.api_uri;
    }

    /**
     * Obtains authentication token based on username and password input
     * @param username Username credential
     * @param password Password credential
     */
    getAuthToken(username: string, password: string): Observable<{ token: string }> {
        return this.http
            .post<{ token: string }>(`${this.apiUrl}/auth/login/`, {
                username,
                password,
            })
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Obtains a new authentication token based on an existing one
     * @param token Old authentication token
     */
    refreshAuthToken(token: string): Observable<{ token: string }> {
        return this.http
            .post<{ token: string }>(this.apiUrl + '/auth/refresh/', {
                token,
            })
            .pipe(requestFailedOperator, catchError(this.httpErrorHandler)) as Observable<{
            token: string;
        }>;
    }

    /**
     * Change user password
     * @param old_password Old password
     * @param new_password New password
     */
    changePassword(old_password: string, new_password: string): Observable<any> {
        return this.http
            .put<any>(this.apiUrl + '/core/change-password/', {
                old_password,
                new_password,
            })
            .pipe(requestFailedOperator, catchError(this.httpErrorHandler)) as Observable<any>;
    }

    /**
     * Returns an array of clients
     */
    getClients(): Observable<Client[]> {
        return this.http
            .get<Client[]>(`${this.apiUrl}/core/clients/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Creates a new client
     */
    createClient(fiscal_number: any, name: any): Observable<Client> {
        return this.http
            .post<Client>(`${this.apiUrl}/core/clients/`, {
                fiscal_number,
                name,
            })
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Updates a client
     * @param clientId The client's id
     * @param data The properties to be updated
     */
    updateClient(clientId: number, data: any): Observable<Client> {
        return this.http
            .patch<Client>(`${this.apiUrl}/core/clients/${clientId}/`, data)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Deletes a client
     * @param clientId The client's id
     */
    deleteClient(clientId: number): Observable<any> {
        return this.http
            .delete<any>(`${this.apiUrl}/core/clients/${clientId}/`)
            .pipe(requestFailedOperator, catchError(this.httpErrorHandler));
    }

    /**
     * Returns an array of crop types
     */
    getApiaCropTypes(): Observable<CropType[]> {
        return this.http
            .get<CropType[]>(`${this.apiUrl}/nomenclature/apia_crop_types/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Creates fields for the given client
     * @param fieldsArray Array of fields to be created
     * @param clientId The client's id
     * @param replaceFields If true, all existing fields will be deleted.
     */
    createFields(
        fieldsArray: Field[],
        clientId: number,
        replaceFields: boolean = false,
    ): Observable<any> {
        const params = {};
        if (replaceFields) {
            params['replace'] = true;
        }
        return this.http
            .post<Field[]>(`${this.apiUrl}/core/clients/${clientId}/fields/`, fieldsArray, {
                params,
            })
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Returns client set data for the given client ids.
     * For each client, only the current year's set is considered.
     */
    getClientSets(clientIds: number[] = []): Observable<ClientSet[]> {
        // This will send to the backend "clients=1&clients=2&clients=3...etc"
        const qString = stringify({ clients: clientIds });
        const params = new HttpParams({ fromString: qString });

        return this.http
            .get<ClientSet[]>(`${this.apiUrl}/core/clients/fields/`, { params })
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Returns an array of fields for the given client
     * @param clientId The client's id
     */
    getFields(clientId: number): Observable<Field[]> {
        return this.http
            .get<Field[]>(`${this.apiUrl}/core/clients/${clientId}/fields/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Returns a field for the given client
     * @param clientId The client's id
     * @param fieldId The field's id
     */
    getField(clientId: number, fieldId: number): Observable<Field> {
        return this.http
            .get<Field>(`${this.apiUrl}/core/clients/${clientId}/fields/${fieldId}/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Deletes a field
     * @param clientId The client's id
     * @param fieldId: The field's id
     */
    deleteField(clientId: number, fieldId: number): Observable<any> {
        return this.http
            .delete<any>(`${this.apiUrl}/core/clients/${clientId}/fields/${fieldId}/`)
            .pipe(requestFailedOperator, catchError(this.httpErrorHandler));
    }

    /**
     * Updates a field with the given properties
     * @param clientId The client's id
     * @param fieldId: The field's id
     * @param data An object containing the properties to be changed and their new values
     */
    updateField(clientId: number, fieldId: number, data: any): Observable<any> {
        return this.http
            .patch<any>(`${this.apiUrl}/core/clients/${clientId}/fields/${fieldId}/`, data)
            .pipe(requestFailedOperator, catchError(this.httpErrorHandler));
    }

    /**
     * Returns an array of pins for the given client
     * @param clientId The client's id
     */
    getPinsOfClient(clientId: number): Observable<Pin[]> {
        return this.http
            .get<Pin[]>(`${this.apiUrl}/core/clients/${clientId}/notes/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Returns an array of pins for the given client, assigned to the given field
     * @param clientId The client's id
     * @param fieldId The id of the field to which the pins are assigned
     */
    getPinsOfField(clientId: number, fieldId: number): Observable<Pin[]> {
        return this.http
            .get<Pin[]>(`${this.apiUrl}/core/clients/${clientId}/fields/${fieldId}/notes/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Creates a pin for the given client
     * @param clientId The client's id
     */
    createPinOfClient(clientId: number, pin: Pin): Observable<Pin> {
        return this.http
            .post<Pin>(`${this.apiUrl}/core/clients/${clientId}/notes/`, pin)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Creates a pin for the given client, assigned to the given field
     * @param clientId The client's id
     * @param fieldId The id of the field to which the pin is assigned
     * @param pin Pin object
     */
    createPinOfField(clientId: number, fieldId: number, pin: Pin): Observable<Pin> {
        return this.http
            .post<Pin>(`${this.apiUrl}/core/clients/${clientId}/fields/${fieldId}/notes/`, pin)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Deletes a pin
     * @param clientId The client's id
     * @param pinId The id of the pin to be deleted
     */
    deletePin(clientId: number, pinId: number): Observable<any> {
        return this.http
            .delete<any>(`${this.apiUrl}/core/clients/${clientId}/notes/${pinId}/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Updates a pin
     * @param clientId The client's id
     * @param pinId The id of the pin to be updated
     * @param data The properties to be updated
     */
    updatePin(clientId: number, pinId: number, data: any): Observable<any> {
        return this.http
            .patch<any>(`${this.apiUrl}/core/clients/${clientId}/notes/${pinId}/`, data)
            .pipe(catchError(this.httpErrorHandler));
    }

    getPinImagesArchive(pinId: number): Observable<any> {
        return this.http
            .get(`${this.apiUrl}/core/notes/${pinId}/images-archive/`, {
                responseType: 'blob',
            })
            .pipe(requestFailedOperator, catchError(this.httpErrorHandler));
    }

    /**
     * Returns an array of documents for the given client
     * @param clientId The associated client's id
     */
    getDocuments(clientId: number): Observable<Document[]> {
        return this.http
            .get<Document[]>(`${this.apiUrl}/core/clients/${clientId}/documents/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Deletes a document
     * @param clientId The client's id
     * @param documentId The id of the file to be deleted
     */
    deleteDocument(clientId: number, documentId: number): Observable<any> {
        return this.http
            .delete<any>(`${this.apiUrl}/core/clients/${clientId}/documents/${documentId}/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Exports the requested document
     * @param clientId The client's id
     * @param documentId The id of the file to be deleted
     */
    exportDocument(clientId: number, documentId: number): Observable<any> {
        return this.http
            .get(`${this.apiUrl}/core/clients/${clientId}/documents/${documentId}/`, {
                responseType: 'blob',
            })
            .pipe(requestFailedOperator, catchError(this.httpErrorHandler));
    }

    /**
     * Exports the stats document
     * @param year The requested year for the stats
     */
    exportStats(year: number): Observable<any> {
        const params = { year: `${year}` };

        return this.http
            .get(`${this.apiUrl}/stats/fields/export/`, { params })
            .pipe(requestFailedOperator, catchError(this.httpErrorHandler));
    }

    /**
     * Returns an array of tasks assigned by the current user, using pagination
     */
    getTasksPage(page: number): Observable<TaskPage> {
        return this.http
            .get<TaskPage>(`${this.apiUrl}/core/tasks-paginated/`, { params: { page } })
            .pipe(catchError(this.httpErrorHandler));
    }

    getTask(taskId: number): Observable<Task> {
        return this.http
            .get<Task>(`${this.apiUrl}/core/tasks/${taskId}/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Creates a task for the current user
     * @param task The task object
     */
    createTask(task: any): Observable<Task> {
        return this.http
            .post<Task>(`${this.apiUrl}/core/tasks/`, {
                ...task,
                fields: _.map(task.fields, (field) => field.id),
            })
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Updates a task
     * @param taskId The id of the task to be updated
     * @param data The properties to be updated
     */
    updateTask(taskId: number, data: any): Observable<Task> {
        if (data.fields) {
            data.fields = _.map(data.fields, (field) => field.id);
        }

        return this.http
            .patch<Task>(`${this.apiUrl}/core/tasks/${taskId}/`, data)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Deletes a task
     * @param taskId The id of the task to be deleted
     */
    deleteTask(taskId: number): Observable<any> {
        return this.http
            .delete<any>(`${this.apiUrl}/core/tasks/${taskId}/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Returns a list of documents for the given task
     */
    getTaskDocuments(taskId: any): Observable<Task[]> {
        return this.http
            .get<Task[]>(`${this.apiUrl}/core/tasks/${taskId}/documents/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Returns an array of permission strings
     */
    getPermissions(): Observable<string[]> {
        return this.http
            .get<string[]>(`${this.apiUrl}/core/permissions/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Returns an array of users
     */
    getUsers(): Observable<any[]> {
        return this.http
            .get<any[]>(`${this.apiUrl}/core/users/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Returns an array of settings
     */
    getSettings(): Observable<GlobalSetting[]> {
        return this.http
            .get<GlobalSetting[]>(`${this.apiUrl}/settings/globals/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Updates a global setting
     * @param id The id of the setting to be updated
     * @param data The properties to be updated
     */
    updateSetting(id: number, data: any): Observable<GlobalSetting> {
        return this.http
            .patch<GlobalSetting>(`${this.apiUrl}/settings/globals/${id}/`, data)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Returns an object of type: { fieldId: NdviData } containing NDVI data
     * @param clientId The client's id
     */
    getNdviIndex(clientId: number): Observable<NdviData[]> {
        return this.http
            .get<NdviData[]>(`${this.apiUrl}/ndvi/clients/${clientId}/index/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Returns an array of scan dates
     * @param clientId The client's id
     * @param fieldId The field's id
     */
    getFieldNdviIndex(clientId: number, fieldId: number): Observable<NdviData[]> {
        return this.http
            .get<NdviData[]>(`${this.apiUrl}/ndvi/clients/${clientId}/fields/${fieldId}/index/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Returns historical weather data for a field
     * @param fieldId The field's id
     * @param isoDate The date in ISO format
     */
    getHistoricalWeatherData(fieldId: number, isoDate: string): Observable<any> {
        return this.http
            .get<any>(`${this.apiUrl}/weather/historical/fields/${fieldId}/${isoDate}/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Return the current user profile
     */
    getUserProfile(): Observable<{ user: User; groups: string[] }> {
        return this.http
            .get<{ user: User; groups: string[] }>(`${this.apiUrl}/core/profile/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Returns the statistics for all fields
     *
     * @param year: Optionally get stats for a year
     */
    getFieldsCropTypeStats(
        year?: number,
    ): Observable<[{ apia_crop_type: number; computed_area__sum: number }]> {
        const params = year ? { year: `${year}` } : {};

        return this.http
            .get<
                [{ apia_crop_type: number; computed_area__sum: number }]
            >(`${this.apiUrl}/stats/fields/`, { params })
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * User ANAF webservice to retrieve company details
     *
     * @param companyFiscalCode Company's fiscal code
     */
    getAnafCompanyDetails(companyFiscalCode: string): Observable<any> {
        return this.http
            .get(`${this.apiUrl}/core/anaf/${companyFiscalCode}/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Request a vegetation report for a given client
     * @param clientId Client internal id
     */
    requestClientVegetationReport(clientId: number): Observable<any> {
        return this.http
            .post(`${this.apiUrl}/reporting/vegetation/request/`, {
                client_id: clientId,
            })
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Get a list of vegetation reports
     */
    getVegetationReports(): Observable<any> {
        return this.http
            .get(`${this.apiUrl}/reporting/vegetation/`)
            .pipe(catchError(this.httpErrorHandler));
    }

    /**
     * Logs the error and throws it to the observable where it was called
     * @param errorResponse The error received
     */
    private httpErrorHandler(errorResponse: Response): Observable<never> {
        console.error(errorResponse);
        return throwError(errorResponse || 'API Error');
    }
}
