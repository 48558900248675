import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from '@shared/services';
import { environment } from '@environments/environment';
import { StorageService } from '@shared/services/storage.service';
import _ from 'lodash';
import { differenceInSeconds } from 'date-fns';
import moment from 'moment';
import { AnafCompanyDetails, Client } from '@shared/models/client';

@Injectable({
    providedIn: 'root',
})
export class ClientsService {
    CurrentClient: BehaviorSubject<Client> = new BehaviorSubject<Client>(null);
    Clients: BehaviorSubject<Client[]> = new BehaviorSubject<Client[]>([]);

    private isLoggingEnabled = !environment.production;

    /**
     * The timestamp of the last getClients() API call. This restricts the getClients()
     * request to once every 3 seconds, so it doesn't get called twice in succession.
     */
    private GetClientsTimestamp: Date = new Date('1970-01-01');

    constructor(private storage: StorageService, private apiService: ApiService) {}

    eraseClientsData = (): void => {
        this.Clients.next([]);
        this.setCurrentClient(null);
    };

    /**
     * Sets the current client
     */
    setCurrentClient(client: Client): void {
        this.CurrentClient.next(client);

        if (client) {
            this.storage.setLastClientId(client.id.toString());
        }
    }

    /**
     * Retrieves a list of clients
     */
    getClients(): Observable<Client[]> {
        const now: Date = new Date();

        if (differenceInSeconds(now, this.GetClientsTimestamp) < 3) {
            return of([]);
        } else {
            this.GetClientsTimestamp = new Date();

            return this.apiService.getClients().pipe(
                tap((clients: Client[]) => {
                    _.forEach(
                        clients,
                        (client: Client) =>
                            (client.moment = moment.utc(client.last_uploaded_at).toISOString())
                    );

                    // console.warn('[Clients-Service] getClients()', clients);

                    this.Clients.next(clients);
                    if (!_.isEmpty(clients) && !this.CurrentClient.getValue())
                        this.initializeCurrentClient(clients);
                })
            );
        }
    }

    /**
     * Creates a new client
     */
    createClient(fiscalNumber: string, companyName: string): Observable<Client> {
        return this.apiService.createClient(fiscalNumber, companyName).pipe(
            tap((createdClient: Client) => {
                this.Clients.next([...this.Clients.getValue(), createdClient]);
            })
        );
    }

    /**
     * Updates a client with the given properties. Must follow-up with a getClients() request,
     * in order for the changes to be visible
     * @param clientId The client's id
     * @param data The properties to be updated
     */
    updateClient(
        data: any,
        clientId: number = this.CurrentClient.getValue().id
    ): Observable<Client> {
        return this.apiService.updateClient(clientId, data);
    }

    /**
     * Deletes a client
     * @param clientId The client's id
     */
    deleteClient(clientId: number): Observable<any> {
        return this.apiService.deleteClient(clientId).pipe(
            tap(() => {
                const clients: Client[] = this.Clients.getValue();
                this.Clients.next(clients.filter((client) => client.id !== clientId));

                /**
                 * If the deleted client matches the current one,
                 * the current client is switched
                 */
                if (this.CurrentClient.getValue().id === clientId) {
                    this.setCurrentClient(
                        this.Clients.getValue().length > 0 ? this.Clients.getValue()[0] : null
                    );
                }
            })
        );
    }

    /**
     * Initializes the current client, considering the one used in the last session if it exists
     */
    initializeCurrentClient(clients: Client[]): void {
        const lastUsedClientId: string = this.storage.getLastClientId();
        const lastUsedClient: Client = _.find(
            clients,
            (client: Client) => client.id.toString() === lastUsedClientId
        );

        this.setCurrentClient(lastUsedClient || clients[0]);
    }

    /**
     * User ANAF webservice to retrieve company details
     *
     * @param companyFiscalCode Company's fiscal code
     */
    getAnafCompanyDetails(companyFiscalCode: string): Observable<AnafCompanyDetails> {
        const fiscalCodeNumber = companyFiscalCode.replace(/\D/g, '');

        return this.apiService.getAnafCompanyDetails(fiscalCodeNumber);
    }

    isFiscalCodeValid = (value: string): boolean => {
        let controlNumber = 753217532;
        const fiscalCodeString: string = value.replace(/\D/g, '');
        let fiscalCodeNumber: number = Number.parseInt(fiscalCodeString);

        if (_.size(fiscalCodeString) > 10 || _.size(fiscalCodeString) < 2) {
            return false;
        }

        const controlDigit: number = fiscalCodeNumber % 10;
        fiscalCodeNumber = Math.floor(fiscalCodeNumber / 10);

        let aux = 0;

        while (fiscalCodeNumber > 0) {
            aux += (fiscalCodeNumber % 10) * (controlNumber % 10);
            fiscalCodeNumber = Math.floor(fiscalCodeNumber / 10);
            controlNumber = Math.floor(controlNumber / 10);
        }

        let secondControlDigit: number = (aux * 10) % 11;

        if (secondControlDigit === 10) {
            secondControlDigit = 0;
        }

        return controlDigit === secondControlDigit;
    };

    /**
     * Request client vegetation report
     * @param clientId Client internal ID
     */
    requestClientVegetationReport = (clientId: number): Observable<any> => {
        return this.apiService.requestClientVegetationReport(clientId);
    };
}
